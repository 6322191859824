<template>
  <div>
    <div class="w-50 mx-auto" v-if="submitted">
      <loader-info></loader-info>
    </div>
    <div v-if="completed">
      <div class="text-center">
        <font-awesome-icon icon="check-circle" class="text-success fa-5x my-3" />
        <h5>
          <strong>
            Richiesta inviata con successo
          </strong>
        </h5>
      </div>
      <div class="mt-5 text-center">
        <p>
          Centrale Rischi Pagamenti ha preso in carico la tua richiesta.
          Se hai richiesto un Business Plan, sarai contattato da un analista.
          <br><br>
          Se hai richiesto un MF Report oppure un Benchmark di settore, a breve ti sarà consegnato
          il prodotto richiesto: nel tuo profilo, accedi all'elenco dei servizi richiesti e clicca
          sulla richiesta <strong>{{reportName}}</strong>. Troverai allegato il documento pronto da scaricare.
        </p>
        <p>
          Se desideri ricaricare il tuo wallet, segui le istruzioni riportate al seguente link:
          <br>
          <router-link
            to="/wallet"
            class="btn btn-info text-no-decoration mt-3"
          >
            Ricarica wallet
          </router-link>
        </p>
      </div>
    </div>
    <div v-if="hasError" class="text-center">
      <font-awesome-icon icon="exclamation-circle" class="text-danger fa-5x my-3" />
      <h5>
        <strong>
          Si è verificato un errore con la richiesta.
        </strong>
      </h5>
      <p class="text-underline">
        <router-link to="/">Torna alla dashboard</router-link>
      </p>
    </div>
  </div>
</template>

<script>
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'CdvRequestSend',
  components: { LoaderInfo },
  props: {
    submitted: Boolean,
    completed: Boolean,
    hasError: Boolean,
    reportName: String,
  },
};
</script>

<style scoped>

</style>
